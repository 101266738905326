import * as React from 'react'
import { Link, navigate } from 'gatsby'
import '~/style/common.scss'
import BrainLargeBlueImage from '~/images/brainLargeBlue.png'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import AppSize from '~/libs/AppSize'
import AppStyle from '~/libs/AppStyle'
import { AppLayout, AppLayoutHandles } from '~/components/AppLayout'
import ContentCss from '~/libs/ContentCss'
import Section1 from '~/components/section/Section1'
import Section2 from '~/components/section/Section2'
import Section3 from '~/components/section/Section3'
import Section4 from '~/components/section/Section4'
import Section5 from '~/components/section/Section5'
import Section6 from '~/components/section/Section6'
import Section7 from '~/components/section/Section7'
import Section8 from '~/components/section/Section8'
import Section9 from '~/components/section/Section9'
import Section10 from '~/components/section/Section10'
import Section11 from '~/components/section/Section11'
import Section12 from '~/components/section/Section12'
import SectionContact from '~/components/section/SectionContact'
import AppGeneral from '~/libs/AppGeneral'
import UtilContent from '~/libs/UtilContent'
import ScrollBar from '~/components/ScrollBar'

interface Content {
  body: React.FC
}

const ReadablePage = () => {
  const layoutRef = useRef<AppLayoutHandles>(null)
  const scrollerRef = useRef<HTMLDivElement>(null)
  const scrollBodyRef = useRef<HTMLDivElement>(null)
  const initialBrainRef = useRef<HTMLDivElement>(null)
  const sectionMaskRef = useRef<HTMLDivElement>(null)
  const sectionListRef = useRef<HTMLDivElement>(null)
  const IS_ABORT_SCALE_DOWN = true

  // let scrollCounter = 0

  const [totalWidth, setTotalWidth] = useState(0)
  const [scrollRate, setScrollRate] = useState(0)
  const [scrollCounter, setScrollCounter] = useState(0)

  const contentList: Content[] = [
    { body: Section1 },
    { body: Section2 },
    { body: Section3 },
    { body: Section4 },
    { body: Section5 },
    { body: Section6 },
    { body: Section7 },
    { body: Section8 },
    { body: Section9 },
    { body: Section10 },
    { body: Section11 },
    { body: Section12 },
    { body: SectionContact },
  ]
  const contactSec = contentList.length

  const scrollToSection = (secNum: number, centering = false) => {
    UtilContent.scrollToSection(secNum, centering, 'readable', scrollBodyRef)
    setScrollRate(UtilContent.getCurrentScrollRate(scrollBodyRef))
  }

  const toggleModeHandler = () => {
    const sec = UtilContent.getSectionByScrollPosition(contactSec, 'readable')
    AppGeneral.Sound.playSeIllegibleOnlyEnter()
    void (async () => {
      await navigate(`/illegible?section=${sec}`)
    })()
  }

  const clickMenuHandler = (secNum: number) => {
    scrollToSection(secNum, true)
  }

  const scrollHandler = (e: Event) => {
    setScrollRate(UtilContent.getCurrentScrollRate(scrollBodyRef))
  }

  const scrollBarSliderMove = (rate: number) => {
    UtilContent.scrollToRate(rate, scrollBodyRef)
    setScrollRate(rate)
  }
  const scrollBarArrowTick = (isUp: boolean) => {
    UtilContent.scrollToOffset(scrollBodyRef, isUp, setScrollRate)
  }
  const onKeyDown = (e: KeyboardEvent) => {
    UtilContent.onKeyDown(e, scrollBodyRef, setScrollRate)
  }
  const onBodyTouchStart = (e: TouchEvent) => {
    UtilContent.onBodyTouchStart(e, scrollBodyRef)
  }
  const onBodyTouchMove = (e: TouchEvent) => {
    UtilContent.onBodyTouchMove(e, scrollBodyRef, setScrollRate)
  }
  const onResize = () => {
    setTotalWidth(UtilContent.getTotalWidth(scrollBodyRef))
    setScrollRate(UtilContent.getCurrentScrollRate(scrollBodyRef))
    setTimeout(() => {
      // adjustSectionMinHeight()
    }, 1000)
  }

  useEffect(() => {
    setScrollCounter(scrollCounter + 1)
    if (scrollCounter % 11 === 0) {
      layoutRef.current?.walk()
    }
  }, [scrollRate])

  useLayoutEffect(() => {
    const sectionListWidth = UtilContent.getTotalWidth(sectionListRef)
    setTotalWidth(sectionListWidth)
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(ScrollToPlugin)

    let pin: gsap.core.Tween | null = null
    if (AppGeneral.isExchangeScroll) {
      pin = UtilContent.getScrollTriggerTween(scrollBodyRef, sectionListWidth)
    }
    return () => {
      if (pin) {
        UtilContent.killScrollTriggerTween(pin)
      }
    }
  }, [])

  const adjustSectionMinHeight = () => {
    if (typeof document === `undefined`) {
      return
    }
    if (!sectionListRef.current) {
      return
    }
    const secH = sectionListRef.current.offsetHeight - 24 - 46

    const needSecH = AppSize.getWinWidth() < AppSize.smNum ? 440 : 540

    const elms = document.getElementsByClassName('blueSecContentScale')
    // for (const elm of elms) {
    for (let i = 0; i < elms.length; i++) {
      const elm = elms[i] as HTMLElement
      if (secH >= needSecH) {
        elm.style.transform = `scale(1)`
      } else {
        elm.style.transform = `scale(${secH / needSecH})`
        // elm.style.height = String(elm.clientHeight * (secH / needSecH)) + 'px'
      }
    }
  }

  useEffect(() => {
    const matches = location.search.match(/section=(\d+)/)
    if (matches) {
      scrollToSection(parseInt(matches[1]), false)

      // MBの場合,scrollToSection内のsetScrollRateでゼロをセットしてしまうので無理やり
      setTimeout(() => {
        setScrollRate(UtilContent.getCurrentScrollRate(scrollBodyRef))
      }, 500)
    }

    // たまにリロード時に幅計算がおかしいので念の為やってみる
    setTimeout(() => {
      setTotalWidth(UtilContent.getTotalWidth(sectionListRef))
    }, 500)

    const mainTL = gsap.timeline()
    mainTL
      .from(sectionMaskRef.current, { autoAlpha: 1 })
      .to(sectionMaskRef.current, {
        autoAlpha: 0,
        delay: 2.1,
        duration: 0.7,
      })
      .to(
        initialBrainRef.current,
        {
          display: 'none',
        },
        '>'
      )

    adjustSectionMinHeight()
    const scrollBody = scrollBodyRef.current
    if (scrollBody) {
      scrollBody.addEventListener('touchstart', onBodyTouchStart)
      scrollBody.addEventListener('touchmove', onBodyTouchMove, {
        passive: false,
      })
    }
    window.addEventListener('keyup', UtilContent.onKeyUp)
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('scroll', scrollHandler, false)
    window.addEventListener('resize', onResize)
    return () => {
      if (scrollBody) {
        scrollBody.removeEventListener('touchstart', onBodyTouchStart)
        scrollBody.removeEventListener('touchmove', onBodyTouchMove)
      }
      window.removeEventListener('keyup', UtilContent.onKeyUp)
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('scroll', scrollHandler, false)
      window.removeEventListener('resize', onResize)
    }
  }, [])
  return (
    <AppLayout
      title={'可読コンテンツ'}
      isMain={true}
      onClickMenu={clickMenuHandler}
      onToggleMode={toggleModeHandler}
      pageMode={'readable'}
      showVolume={true}
      ref={layoutRef}
    >
      <div css={ContentCss.initialBrainBlue} ref={initialBrainRef}>
        <img src={BrainLargeBlueImage} />
      </div>
      <ScrollBar
        posRate={scrollRate}
        scrollTo={scrollBarSliderMove}
        arrowTick={scrollBarArrowTick}
      />
      <div css={ContentCss.scroller} ref={scrollerRef}>
        <div css={ContentCss.scrollBody(totalWidth)} ref={scrollBodyRef}>
          <div css={ContentCss.sectionMask} ref={sectionMaskRef}></div>
          <div css={ContentCss.sectionList} ref={sectionListRef}>
            {contentList.map((c, idx) => {
              return (
                <div
                  css={ContentCss.sectionPullMarginY}
                  id={`readSection${idx + 1}`}
                  key={`section${idx}`}
                >
                  {idx === contentList.length - 1 ? (
                    <SectionContact magnet={true} />
                  ) : (
                    <c.body />
                  )}
                </div>
              )
            })}
          </div>
          <div css={ContentCss.sectionListBg}></div>
        </div>
      </div>
    </AppLayout>
  )
}

export default ReadablePage
